import { useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import FormSubHeading from "../components/FormSubHeading";
import "./CreateCase.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DeleteButton } from "../components/TableButtons";
import { GENDERS, PHONE_CODES, CONDITIONS } from "../constants";
import { addCase } from "../services/medicalCases";
import { getHoldersMatchingCI, getPeopleMatchingCI } from "../services/people";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import InputWithSuggestions from "../components/InputWithSuggestions";
import RequirementModal from "./RequirementModal";
import { useDiagnosisList, useSubsidiaries } from "../hooks/queries";
import { Divider } from "primereact/divider";
import VSieteDateInput from "../components/VSieteDateInput";
import { Dialog } from "primereact/dialog";

/**
 * @typedef API_Person Structure of the Person object returned by the API
 * @property {number} id
 * @property {string} ci
 * @property {string} name
 * @property {"M" | "F"} sex
 * @property {string} birthdate
 * @property {string} phone
 * @property {string} subsidiary
 * @property {boolean} is_holder
 * @property {string | null} holder
 */

/**
 * @typedef TableRequirement Structure of each requirement shown on the table
 * @property {number} requirementType
 * @property {string} requirementTypeLabel
 * @property {number} requirement
 * @property {string} requirementLabel
 * @property {number} medicalCenter
 * @property {string} medicalCenterLabel
 */

/**
 * Tabla de requerimientos
 * @param {object} props
 * @param {TableRequirement[]} props.requirements
 * @param {React.Dispatch<React.SetStateAction<TableRequirement[] | null>>} props.setRequirementList
 * @param {(boolean) => void} props.setShowRequirementModal
 * @param {React.Dispatch<React.SetStateAction<TableRequirement | null>>} props.setEditValues
 * @param {React.Dispatch<React.SetStateAction<number | null>>} props.setCenterSelected
 */
function RequirementsTable({
  requirements,
  setRequirementList,
  setShowRequirementModal,
  setEditValues,
  setCenterSelected,
}) {
  /**
   * Remove requirement from list
   * @param {TableRequirement} requirement
   */
  function deleteRequirement(requirement) {
    const newList = requirements.filter((req) => req !== requirement);
    setRequirementList(newList);
    if (newList.length === 0) {
      setCenterSelected(null);
    }
  }

  /**
   * Show action buttons
   * @param {TableRequirement} row
   */
  const actionBody = (row) => (
    <div className="flex">
      {/* <EditButton onClick={() => {
        setEditValues(row)
        setShowRequirementModal(true);
      }} /> */}
      <DeleteButton onClick={() => deleteRequirement(row)} />
    </div>
  );

  return (
    <DataTable value={requirements} className="mt-5">
      <Column field="requirementTypeLabel" header="Tipo de requerimiento" />
      <Column field="requirementLabel" header="Requerimiento" />
      <Column field="medicalCenterLabel" header="Centro médico" />
      <Column header="Acciones" body={actionBody} />
    </DataTable>
  );
}

const INITIAL_PATIENT_FORM = {
  ciType: "V",
  ci: "",
  name: "",
  sex: null,
  birthDate: "",
  phoneCode: "58412",
  phoneNumber: "",
  diagnosis: null,
  client: null,
  subsidiary: null,
  isHolder: true,
  shouldCreatePatient: false,
};

const INITIAL_HOLDER_FORM = {
  ciType: "V",
  ci: "",
  name: "",
  sex: null,
  birthDate: "",
  birthDay: null,
  birthMonth: null,
  birthYear: null,
  phoneCode: "58412",
  phoneNumber: "",
  shouldCreateHolder: false,
  condition: "A",
};

/**
 * Person search item template
 * @param {(item: API_Person) => void} onClick
 */
function createSearchItemTemplate(onClick) {
  /** @param {API_Person} item */
  return (item) => (
    <>
      <span className="py-1" onClick={() => onClick(item)}>
        {item.ci} - {item.name}
      </span>
      <Divider />
    </>
  );
}

/**
 * Formulario para crear un nuevo caso médico
 */
const FormComponent = () => {
  // Form state
  const [patientForm, setPatientForm] = useState(INITIAL_PATIENT_FORM);
  const [holderForm, setHolderForm] = useState(INITIAL_HOLDER_FORM);
  const [inputsLocked, setInputsLocked] = useState("");
  const [holderLocked, setHolderLocked] = useState(false);
  const [patientLocked, setPatientLocked] = useState(false);

  // Requirements
  const [showRequirementModal, setShowRequirementModal] = useState(false);
  const [requirementList, setRequirementList] = useState([]);
  const [editValues, setEditValues] = useState(null);
  const [selectedMedicalCenter, setSelectedMedicalCenter] = useState(null);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [duplicateCase, setDuplicateCase] = useState(false);
  const [patientSelected, setPatientSelected] = useState(false);


  // Get diagnosis list
  const diagnosisQuery = useDiagnosisList();

  // Get subsidiaries
  const subsidiariesQuery = useSubsidiaries();

  // Get holder by CI
  const holderQuery = useQuery({
    queryKey: ["holder", holderForm.ciType + holderForm.ci],
    queryFn: () => getHoldersMatchingCI(holderForm.ciType + holderForm.ci),
    enabled: holderForm.ci.length >= 4,
  });

  // Get patient by CI
  const patientQuery = useQuery({
    queryKey: ["patient", patientForm.ciType + patientForm.ci],
    queryFn: () => getPeopleMatchingCI(patientForm.ciType + patientForm.ci),
    enabled: patientForm.ci.length >= 4,
  });

  const queryClient = useQueryClient();

  // Mutation for new case
  const newCaseMutation = useMutation({
    mutationFn: () =>
      addCase({
        patient: patientForm,
        holder: holderForm,
        requirements: requirementList,
        duplicate: duplicateCase
      }),
    onSuccess: ({ type, message, code, data }) => {
      if (type === "error") {
        if( Array.isArray(code) && code.includes("case_exists")){
          console.log("caseExisr")
          handleDuplicateCase();
        }else{
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
  
          });
        }
      } else {
        queryClient.invalidateQueries({
          queryKey: ["medicalCases"],
        });
        toast.current.show({
          severity: "success",
          summary: "Éxito",
          detail: "Caso médico registrado",
        });
        setPatientForm(INITIAL_PATIENT_FORM);
        setHolderForm(INITIAL_HOLDER_FORM);
        setRequirementList([]);
        setInputsLocked("");
        setSelectedMedicalCenter(null);
        holderSearchRef.current.hide();
        patientSearchRef.current.hide();
        setDuplicateCase(false);
      }
    },
  });

  // Validate fields being set so requirement can be added
  const canAddRequirement =
    (patientForm.isHolder && holderForm.ci) ||
    Object.keys(patientForm).every((key) => {
      return patientForm[key] !== null && patientForm !== "";
    });

  // Validate fields being set so case can be saved
  const canSaveCase = canAddRequirement && requirementList.length > 0;

  // Form message toast
  const toast = useRef(null);

  // Patient and holder name inputs
  const holderNameRef = useRef(null);
  const patientNameRef = useRef(null);

  // Search overlays
  const holderSearchRef = useRef(null);
  const patientSearchRef = useRef(null);

  /**
   * Key up event for holder search
   * @param {KeyboardEvent} e
   */
  const onHolderSearchEnter = (e) => {
    if (e.key === "Escape") {
      holderSearchRef.current.hide();
    }
    if (e.key === "Enter") {
      const ci = holderForm.ciType + holderForm.ci;
      if (
        !holderQuery.data ||
        !holderQuery.data.length ||
        ci !== holderQuery.data[0].ci
      ) {
        setHolderForm({ ...holderForm, shouldCreateHolder: true });
        holderSearchRef.current.hide();

        // Wait 50ms for holderNameRef to be set
        setTimeout(() => {
          holderNameRef.current.focus();
        }, 50);
        return;
      }

      selectHolder(holderQuery.data[0]);
    }
  };

  /**
   * Key up event for patient search
   * @param {KeyboardEvent} e
   */
  const onPatientSearchEnter = (e) => {
    if (patientSelected) return;

    if (e.key === "Escape") {
      patientSearchRef.current.hide();
    }
    if (e.key === "Enter") {
      if (!patientQuery.data || !patientQuery.data.length) {
        setPatientForm({ ...patientForm, shouldCreatePatient: true });
        patientSearchRef.current.hide();
        patientNameRef.current.focus();
      } else {
        selectPatient(patientQuery.data[0]);
      }
    }
  };

  /**
   * Sets form state from a selected patient
   * @param {API_Person} holder
   */
  const selectHolder = (holder) => {
    setHolderForm({
      ciType: holder.ci.slice(0, 1),
      ci: holder.ci.slice(1),
      sex: holder.sex,
      birthDate: holder.birthdate.split("-").reverse().join("/"),
      name: holder.name,
      phoneCode: holder.phone.slice(0, 5),
      phoneNumber: holder.phone.slice(5),
      shouldCreateHolder: false,
    });
    setPatientForm({ ...patientForm, isHolder: true });
    setHolderLocked(true);
    holderSearchRef.current.hide();
  };

  /**
   * Sets form state from a selected patient
   * @param {API_Person} patient
   */
  const selectPatient = (patient) => {
    console.log(patient, "selectPatient");
    setPatientForm({
      ...patientForm,
      ciType: patient.ci.slice(0, 1),
      ci: patient.ci.slice(1),
      name: patient.name,
      sex: patient.sex,
      birthDate: patient.birthdate.split("-").reverse().join("/"),
      phoneCode: patient.phone.slice(0, 5),
      phoneNumber: patient.phone.slice(5),
      subsidiary: patient.subsidiary,
      diagnosis: null,
      isHolder: false,
      shouldCreatePatient: false,
    });
    setPatientLocked(true);
    setPatientSelected(true);
    patientSearchRef.current.hide();
  };

  const handleDuplicateCase = () => {
    setShowDuplicateModal(true);
  };

  const confirmDuplicateCase = () => {
    setShowDuplicateModal(false);
    setDuplicateCase(true);
    newCaseMutation.mutate();
  };

  const duplicateModalFooter = (
    <div>
      <Button label="No" icon="pi pi-times" onClick={() => setShowDuplicateModal(false)} className="p-button-text" />
      <Button label="Si" icon="pi pi-check" onClick={confirmDuplicateCase} autoFocus />
    </div>
  );

  const duplicateModalContent = (
    <div>
      <p>¿Está seguro de que desea duplicar este caso?</p>
      {patientForm.isHolder ? (
        <p><strong>Paciente:</strong> {holderForm.name}</p>
      ):
      <p><strong>Paciente:</strong> {patientForm.name}</p>
    }
      <ul>
        {requirementList.map((req, index) => (
          <li key={index}>
            {req.requirementTypeLabel} - {req.requirementLabel} 
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div className="p-fluid">
      <Toast ref={toast} position="top-center" />
      <form>
        {/* DATOS DEL TITULAR */}
        <FormSubHeading title="Datos del Titular" />
        <div className="p-field">
          <label htmlFor="holderCi">Cédula</label>
          <div className="field-with-select">
            <Dropdown
              id="holderCiType"
              name="holderCiType"
              options={["V", "E"]}
              placeholder="Tipo"
              value={holderForm.ciType}
              onChange={(e) =>
                setHolderForm({ ...holderForm, ciType: e.value })
              }
              required
              disabled={inputsLocked === "all"}
            />
            <InputWithSuggestions
              inputName="holderCi"
              inputValue={holderForm.ci}
              inputDisabled={inputsLocked === "all"}
              keyfilter="pint"
              maxLength={9}
              onTextChange={(e) =>
                setHolderForm({ ...holderForm, ci: e.target.value })
              }
              query={holderQuery}
              notFoundBtnLabel="Crear registro de asegurado"
              notFoundBtnClick={() => {
                setHolderForm({ ...holderForm, shouldCreateHolder: true });
                holderSearchRef.current.hide();
                if (holderNameRef.current) {
                  holderNameRef.current.focus();
                }
              }}
              itemTemplate={createSearchItemTemplate(selectHolder)}
              overlayRef={holderSearchRef}
              onSearchEnter={onHolderSearchEnter}
            />
          </div>

          {/* Holder personal data */}
          <div className="p-field mt-3">
            <label htmlFor="holderName">Nombre completo</label>
            <InputText
              id="holderName"
              name="holderName"
              value={holderForm.name}
              onChange={(e) =>
                setHolderForm({ ...holderForm, name: e.target.value })
              }
              disabled={
                holderLocked || inputsLocked === "all" || holderQuery.isLoading
              }
              ref={holderNameRef}
            />
          </div>

          <div className="p-field">
            <label htmlFor="holderSex">Sexo</label>
            <Dropdown
              inputId="holderSex"
              name="holderSex"
              value={holderForm.sex}
              options={GENDERS}
              onChange={(e) => setHolderForm({ ...holderForm, sex: e.value })}
              placeholder="Seleccionar sexo"
              disabled={
                holderLocked || inputsLocked === "all" || holderQuery.isLoading
              }
            />
          </div>
          <div class="formgrid grid">
            <div class="field col">
              <div className="p-field">
                <span>Fecha de nacimiento</span>
                <VSieteDateInput
                  name="holderBirthdate"
                  value={holderForm.birthDate}
                  setValue={(value) =>
                    setHolderForm({ ...holderForm, birthDate: value })
                  }
                  disabled={
                    holderLocked ||
                    inputsLocked === "all" ||
                    holderQuery.isLoading
                  }
                />
              </div>
            </div>
            <div class="field col">
              <div className="p-field">
                <label htmlFor="holderSex">Condición</label>
                <Dropdown
                  inputId="holderSex"
                  name="holderSex"
                  value={holderForm.condition}
                  options={CONDITIONS}
                  onChange={(e) =>
                    setHolderForm({ ...holderForm, condition: e.value })
                  }
                  placeholder="Seleccione condición del trabajador"
                  disabled={
                    holderLocked ||
                    inputsLocked === "all" ||
                    holderQuery.isLoading
                  }
                />
              </div>
            </div>
          </div>
          {/* <div className="p-field">
            <label htmlFor="holderPhoneNumber">Número de teléfono</label>
            <div className="field-with-select">
              <Dropdown
                inputId="holderPhoneCode"
                name="holderPhoneCode"
                options={PHONE_CODES}
                placeholder="Código"
                value={holderForm.phoneCode}
                onChange={(e) =>
                  setHolderForm({ ...holderForm, phoneCode: e.value })
                }
                disabled={inputsLocked === "all" || holderQuery.isLoading}
              />
              <InputText
                id="holderPhoneNumber"
                name="holderPhoneNumber"
                inputMode="numeric"
                keyfilter="pint"
                maxLength={7}
                value={holderForm.phoneNumber}
                onChange={(e) =>
                  setHolderForm({ ...holderForm, phoneNumber: e.target.value })
                }
                disabled={inputsLocked === "all" || holderQuery.isLoading}
              />
            </div>
          </div> */}

          <div className="flex flex-row align-items-center gap-3 pt-2">
            <span>¿El titular es el paciente?</span>
            <SelectButton
              value={patientForm.isHolder}
              onChange={(e) =>
                setPatientForm({ ...patientForm, isHolder: e.value })
              }
              options={[
                { label: "Si", value: true },
                { label: "No", value: false },
              ]}
              disabled={
                inputsLocked === "all" ||
                patientQuery.isLoading ||
                holderQuery.isLoading
              }
              allowEmpty={false}
            />
          </div>
        </div>

        {!patientForm.isHolder && (
          <>
            {/* DATOS DEL PACIENTE */}
            <FormSubHeading title="Datos del Paciente" />

            <div className="p-field">
              <label htmlFor="ciPatient">Cédula</label>
              <div className="field-with-select">
                <Dropdown
                  inputId="patientCiType"
                  name="patientCiType"
                  options={["V", "E"]}
                  placeholder="Tipo"
                  value={patientForm.ciType}
                  onChange={(e) =>
                    setPatientForm({ ...patientForm, ciType: e.value })
                  }
                  required
                  disabled={inputsLocked === "all"}
                />
                <InputWithSuggestions
                  inputName="patientCi"
                  inputValue={patientForm.ci}
                  inputDisabled={inputsLocked === "all"}
                  keyfilter="pint"
                  maxLength={9}
                  onTextChange={(e) =>
                  {
                    setPatientSelected(false);
                    setPatientForm({ ...patientForm, ci: e.target.value, })

                  }}
                  query={patientQuery}
                  notFoundBtnLabel="Crear paciente con esta cédula"
                  notFoundBtnClick={() => {
                    console.log(patientForm, "crar paciente");
                    setPatientForm({
                      ...patientForm,
                      shouldCreatePatient: true,
                    });
                    patientSearchRef.current.hide();
                    if (patientNameRef.current) {
                      patientNameRef.current.focus();
                    }
                  }}
                  itemTemplate={createSearchItemTemplate(selectPatient)}
                  overlayRef={patientSearchRef}
                  onSearchEnter={onPatientSearchEnter}
                />
              </div>
            </div>

            <div className="p-field">
              <label htmlFor="patientName">Nombre completo</label>
              <InputText
                id="patientName"
                name="patientName"
                value={patientForm.name}
                onChange={(e) =>
                  setPatientForm({ ...patientForm, name: e.target.value })
                }
                disabled={
                  patientLocked ||
                  inputsLocked === "all" ||
                  patientQuery.isLoading
                }
                ref={patientNameRef}
              />
            </div>

            <div className="p-field">
              <label htmlFor="gender">Sexo</label>
              <Dropdown
                inputId="gender"
                name="gender"
                value={patientForm.sex}
                options={GENDERS}
                onChange={(e) =>
                  setPatientForm({ ...patientForm, sex: e.value })
                }
                placeholder="Seleccionar sexo"
                disabled={
                  patientLocked ||
                  inputsLocked === "all" ||
                  patientQuery.isLoading
                }
              />
            </div>

            <div className="p-field">
              <span>Fecha de nacimiento</span>
              <VSieteDateInput
                value={patientForm.birthDate}
                setValue={(val) =>
                  setPatientForm((prev) => ({ ...prev, birthDate: val }))
                }
                name="patientBirthdate"
                disabled={
                  patientLocked ||
                  inputsLocked === "all" ||
                  patientQuery.isLoading
                }
              />
            </div>

            {/* <div className="p-field">
              <label htmlFor="phoneNumber">Número de teléfono</label>
              <div className="field-with-select">
                <Dropdown
                  inputId="phoneCode"
                  name="phoneCode"
                  options={PHONE_CODES}
                  placeholder="Código"
                  value={patientForm.phoneCode}
                  onChange={(e) =>
                    setPatientForm({ ...patientForm, phoneCode: e.value })
                  }
                  disabled={inputsLocked === "all" || patientQuery.isLoading}
                />
                <InputText
                  id="phoneNumber"
                  name="phoneNumber"
                  inputMode="numeric"
                  keyfilter="pint"
                  maxLength={7}
                  value={patientForm.phoneNumber}
                  onChange={(e) =>
                    setPatientForm({
                      ...patientForm,
                      phoneNumber: e.target.value,
                    })
                  }
                  disabled={inputsLocked === "all" || patientQuery.isLoading}
                />
              </div>
            </div> */}
          </>
        )}

        <div className="p-field">
          <label htmlFor="phoneNumber">Número de teléfono</label>
          <div className="field-with-select">
            <Dropdown
              inputId="phoneCode"
              name="phoneCode"
              options={PHONE_CODES}
              placeholder="Código"
              value={patientForm.phoneCode}
              onChange={(e) =>{
                 setPatientForm({ ...patientForm, phoneCode: e.value })
                 setHolderForm({ ...holderForm, phoneCode: e.value })
              }}
              disabled={inputsLocked === "all" || patientQuery.isLoading}
            />
            <InputText
              id="phoneNumber"
              name="phoneNumber"
              inputMode="numeric"
              keyfilter="pint"
              maxLength={7}
              value={patientForm.phoneNumber}
              onChange={(e) =>{
                setPatientForm({
                  ...patientForm,
                  phoneNumber: e.target.value,
                })
                setHolderForm({
                  ...holderForm,
                  phoneNumber: e.target.value,
                })
              }}
              disabled={inputsLocked === "all" || patientQuery.isLoading}
            />
          </div>
        </div>

        {/* SELECCIÓN DE DIAGNÓSTICO */}
        <FormSubHeading title="Diagnóstico" />
        <div className="p-field">
          <label htmlFor="diagnosis">Diagnóstico</label>
          <MultiSelect
            inputId="diagnosis"
            name="diagnosis"
            value={patientForm.diagnosis}
            onChange={(e) =>
              setPatientForm({ ...patientForm, diagnosis: e.value })
            }
            options={diagnosisQuery.data ?? []}
            optionValue="id"
            optionLabel="name"
            placeholder="Seleccionar diagnóstico"
            filter
            loading={diagnosisQuery.isLoading}
            disabled={diagnosisQuery.isLoading || inputsLocked === "all"}
            virtualScrollerOptions={{ itemSize: 48, style: { width: "650px" } }}
          />
        </div>

        {/* SELECCIÓN DE FILIAL */}
        <FormSubHeading title="Filial" />
        <div className="p-field">
          <label htmlFor="subsidiary">Filial</label>
          <Dropdown
            inputId="subsidiary"
            name="subsidiary"
            options={subsidiariesQuery.data ?? []}
            value={patientForm.subsidiary}
            onChange={(e) =>
              setPatientForm({
                ...patientForm,
                subsidiary: e.value,
                client:
                  subsidiariesQuery.data.find(
                    (subsidiary) => subsidiary.id === e.value
                  )?.company_client ?? null,
              })
            }
            optionLabel="name"
            optionValue="id"
            placeholder="Seleccionar filial"
            filter
            disabled={
              patientQuery.isLoading || subsidiariesQuery.isLoading
              // ||  inputsLocked !== ""
            }
            loading={subsidiariesQuery.isLoading}
          />
        </div>
      </form>

      {!!requirementList.length && (
        <>
          {/* TABLA DE REQUERIMIENTOS */}
          <FormSubHeading title="Requerimientos" />
          <RequirementsTable
            requirements={requirementList}
            setRequirementList={setRequirementList}
            setShowRequirementModal={setShowRequirementModal}
            setEditValues={setEditValues}
            setCenterSelected={setSelectedMedicalCenter}
          />
        </>
      )}

      <Button
        type="button"
        icon="pi pi-plus"
        label="Añadir requerimiento"
        className="mt-4"
        severity="info"
        disabled={!canAddRequirement}
        loading={newCaseMutation.isPending}
        onClick={() => {
          setEditValues(null);
          setShowRequirementModal(true);
        }}
      />

      {!!canSaveCase && (
        <Button
          type="button"
          icon="pi pi-save"
          label="Guardar caso"
          className="mt-4"
          severity="success"
          loading={newCaseMutation.isPending}
          disabled={newCaseMutation.isPending}
          onClick={() => newCaseMutation.mutate()}
        />
      )}

      {/* MODAL DE REQUERIMIENTOS */}
      <RequirementModal
        showModal={showRequirementModal}
        onHide={() => {
          if (!showRequirementModal) return;
          setEditValues(null);
          setShowRequirementModal(false);
        }}
        setRequirementList={setRequirementList}
        setInputsLocked={setInputsLocked}
        initialValues={editValues}
        patientClient={patientForm.client}
        centerSelected={selectedMedicalCenter}
        setCenterSelected={setSelectedMedicalCenter}
      />

      {/* MODAL DE CONFIRMACIÓN DE DUPLICADO */}
      <Dialog
        header="Confirmar duplicado"
        visible={showDuplicateModal}
        style={{ width: '50vw' }}
        footer={duplicateModalFooter}
        onHide={() => setShowDuplicateModal(false)}
      >
        {duplicateModalContent}
      </Dialog>
    </div>
  );
};

export default FormComponent;
