import { Tag } from "primereact/tag";

/**
 * Devuelve el color de fondo de la etiqueta de status de caso
 * @param {string} status 
 */
const getStatusBackground = (status) => {
  switch (status) {
    case "atendido":
      return "#84cc16";
    case "remesado":
      return "#0ea5e9";
    case "agendado":
      return "#14b8a6";
    case "pendiente":
      return "#f97316";
    case "conciliado":
      return "#7e22ce";
    case "auditado":
      return "#22c55e";
    case "anulado":
      return "#ef4444";
    default:
      return "transparent";
  }
}

/**
 * Etiqueta que muestra el status de un caso médico
 * @param {object} props
 * @param {string} props.value
 */
export default function StatusTag({ value, className }) {
    return <Tag className={className} value={value} style={{ backgroundColor: getStatusBackground(value) }} />;
}
