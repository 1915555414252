/**
 * Get registered login
 * @param {object} params
 * @param {string} params.username
 * @param {string} params.password
 */
export async function authLogin({ username, password }) {
  try {
    const url = process.env.REACT_APP_AUTH_URL;
    // const url = "http://localhost:8002/api-token-auth/" 

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username: username.toLowerCase(), password }),
    });
    const data = await response.json();
    if (data.token) {
      return { type: "success", data };
    } else {
      return { type: "error", message: "Correo o contraseña incorrectas" };
    }
  } catch (error) {
    console.error("Error fetching login", error);
    return { type: "error", message: "Ocurrió un error" };
  }
}

/**
 * Remove session data from local storage
 */
export async function authLogout() {
  localStorage.removeItem("token");
  localStorage.removeItem("role");
  return true;
}


export async function AuthCheck() {
  const token = localStorage.getItem("token");
  if (token) {
    // console.log(true)
    return true;
  } else {
    return false;
  }
}
