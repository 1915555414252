import { useParams } from "react-router-dom";
import Dashboard from "../Layout/Dashboard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import "./Advances.css";
import { currencyFormat, getBankNameFromId } from "../utils";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode, FilterService } from "primereact/api";
import { useBanks, useMedicalCenterAdvances } from "../hooks/queries";
import { UserRole } from "../constants";
import { DeleteButton } from "../components/TableButtons";
import { deleteAdvance } from "../services/advances";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Toast } from "primereact/toast";

/**
 * Applies currency format to the given number
 */
function amountDollarsTemplate(row) {
  return currencyFormat(row.amount_bs);
}

/**
 * Applies tag format to filter dropdown
 * @param {"Cargado" | "Pendiente"} option 
 */
function invoiceFilterTemplate(option) {
  const severity = option === "Cargado" ? "success" : "danger";
  return <Tag severity={severity} value={option} />;
}

// Filter for invoice status
FilterService.register("custom_suport_payment", (value, filter) => {
  if (filter === "Cargado") {
    return value !== null;
  }
  return !value;
});

const invoiceStatusFilterMenu = (options) => (
  <Dropdown
    value={options.value}
    options={["Cargado", "Pendiente"]}
    onChange={(e) => options.filterApplyCallback(e.value)}
    itemTemplate={invoiceFilterTemplate}
    placeholder="Seleccionar status"
    className="p-column-filter"
    showClear
  />
);

/**
 * Listado de Anticipos del Centro Médico
 */
export default function MedicalCenterAdvances() {
  
  // Get current user role
  const role = localStorage.getItem("role");

  // Detail dialog state
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  // Delete dialog state
  const [invoiceToDelete, setInvoiceToDelete] = useState(null);

  // Table filters
  const [filters] = useState({
    reference_payment: { value: null, matchMode: FilterMatchMode.CONTAINS },
    suport_payment: { value: null, matchMode: FilterMatchMode.CUSTOM },
  });

  // Get advances from medical center
  const params = useParams();
  const advancesQuery = useMedicalCenterAdvances(params.centerId);

  // Get banks
  const banksQuery = useBanks();

  const bankReceiverTemplate = (row) => {
    return getBankNameFromId(row.bank_receiver, banksQuery.data);
  }

  // Toast for API responses
  const toastRef = useRef(null);

  /**
   * Applies tag format to invoice
   * @param {typeof items[number]} row
   */
  function invoiceTemplate(row) {
    if (row.suport_payment) {
      return (
        <Button icon="pi pi-check" label="Ver" severity="success" onClick={() => setSelectedInvoice(row)} />
      );
    }
    return <Button icon="pi pi-upload" label="Cargar Archivo" onClick={() => setSelectedInvoice(row)} />;
  }

  return (
    <Dashboard headerTitle="Anticipos" backButtonUrl="/anticipos">
      <h1 style={{ fontSize: "1.5rem" }}>
        Anticipos del centro médico
      </h1>
      <DataTable
        value={advancesQuery.data ?? []}
        style={{ minWidth: "50rem" }}
        stripedRows
        selectionMode="single"
        onRowSelect={(event) => setSelectedInvoice(event.data)}
        filterDisplay="row"
        removableSort
        loading={advancesQuery.isLoading || banksQuery.isLoading}
        emptyMessage="No se encontraron anticipos."
        filters={filters}
      >
        <Column
          field="date"
          header="Fecha"
          sortable
        />
        <Column
          field="amount_dolar"
          header="Monto Bs"
          body={amountDollarsTemplate}
          sortable
        />
        <Column
          field="bank_receiver"
          header="Banco Beneficiario"
          body={bankReceiverTemplate}
        />
        <Column
          field="reference_payment"
          header="Nro. Referencia"
          filter
          filterPlaceholder="Buscar por referencia"
          showFilterMenu={false}
        />
        <Column
          field="description"
          header="Descripción"
          filter
          filterPlaceholder="Buscar por referencia"
          showFilterMenu={false}
        />
        {role !== UserRole.MEDICAL_CENTER && 
          <Column
            field="suport_payment"
            header="Soporte de Pago"
            body={invoiceTemplate}
            filter
            filterElement={invoiceStatusFilterMenu}
            showFilterMenu={false}
          />
          
        }
        {role === UserRole.MASTER &&
          <Column
            header="Acción"
            body={(row) => (
              <DeleteButton onClick={() => setInvoiceToDelete(row)} />
            )}
          />
        }
        {role === UserRole.MEDICAL_CENTER && 
          <Column
            header="Acción"
            body={(row) => (
              <Button
                icon="pi pi-eye"
                label="Ver Detalles"
                onClick={() => setSelectedInvoice(row)}
              />
            )}
          />
        }
      </DataTable>

      <Toast ref={toastRef} position="top-center" />

      <AdvanceDetailDialog
        invoice={selectedInvoice}
        setInvoice={setSelectedInvoice}
        role={role}
        banks={banksQuery.data ?? []}
      />

      {role === UserRole.MASTER &&
        <DeleteInvoiceDialog
          invoice={invoiceToDelete}
          setInvoice={setInvoiceToDelete}
          banks={banksQuery.data ?? []}
          toastRef={toastRef}
        />
      }

    </Dashboard>
  );
}

/**
 * Dialog to show advance details
 */
function AdvanceDetailDialog({ invoice, setInvoice, role, banks }) {
  const [isFileSelected, setIsFileSelected] = useState(false);
  const shouldRenderInvoiceInput = invoice && !invoice.suport_payment;

  return (
    <Dialog
      header="Detalle del anticipo"
      visible={invoice !== null}
      className="advance-modal"
      contentClassName="advance-modal-content"
      onHide={() => {
        if (!invoice) return;
        setInvoice(null);
      }}
      draggable={false}
    >
      <section>
        <p><i className="pi pi-calendar" aria-hidden="true"></i> <strong>Fecha: </strong>{invoice?.date}</p>
        {/* <p><i className="pi pi-dollar" aria-hidden="true"></i> <strong>Monto ($): </strong>{currencyFormat(invoice?.amount_dolar)}</p> */}
        <p><i className="pi pi-dollar" aria-hidden="true"></i> <strong>Monto (Bs.): </strong>{currencyFormat(invoice?.amount_bs)}</p>
        <p><i className="pi pi-arrow-right-arrow-left" aria-hidden="true"></i> <strong>Tasa: </strong>{currencyFormat(invoice?.rate)} Bs.</p>
      </section>
      <section>
        <p><i className="pi pi-hashtag" aria-hidden="true"></i> <strong>Nro. de referencia: </strong>{invoice?.reference_payment}</p>
        <p><i className="pi pi-building-columns" aria-hidden="true"></i> <strong>Banco emisor: </strong>{getBankNameFromId(invoice?.bank_emitter, banks)}</p>
        <p><i className="pi pi-building-columns" aria-hidden="true"></i> <strong>Banco beneficiario: </strong>{getBankNameFromId(invoice?.bank_receiver, banks)}</p>
      </section>
      {role !== UserRole.MEDICAL_CENTER && 
        <section>
          <p><i className="pi pi-receipt" aria-hidden="true"></i> <strong>Soporte:</strong></p>
          {shouldRenderInvoiceInput ?
            <form>
              <FileUpload
                name="invoice"
                chooseLabel="Cargar soporte"
                cancelLabel="Cancelar"
                uploadOptions={{
                  style: {
                    display: "none",
                  },
                }}
                onSelect={() => setIsFileSelected(true)}
              />
                {isFileSelected && <Button id="saveInvoice" severity="success" icon="pi pi-save" label="Guardar soporte" />}
            </form>
            : <p>Soporte cargado (Mostrar vista previa)</p>
          }
        </section>
      }
    </Dialog>
  );
}

/**
 * Dialog to confirm invoice deletion
 */
function DeleteInvoiceDialog({ invoice, setInvoice, banks, toastRef }) {

  /**
   * Handle dialog hiding
   */
  const onHide = () => {
    if (!invoice) return;
    setInvoice(null);
  };

  // Handle advance delete
  const queryClient = useQueryClient();
  const params = useParams();

  const deleteAdvanceMutation = useMutation({
    mutationFn: deleteAdvance,
    onSuccess: ({ type, message }) => {
      if (type === "success") {
        // Refetch
        queryClient.invalidateQueries({
          queryKey: ["advances", params.centerId],
        });
        setInvoice(null);
        // Show message
        toastRef.current?.show({ severity: "success", summary: "Anticipo eliminado", detail: message });
      } else {
        toastRef.current?.show({ severity: "error", summary: "Error", detail: message });
      }
    }
  });

  return (
    <Dialog
      header="Eliminar anticipo"
      visible={invoice !== null}
      onHide={onHide}
      draggable={false}
      style={{ maxWidth: "35rem" }}
    >
      <div className="p-fluid text-center">
        <p>¿Seguro que deseas eliminar este anticipo?</p>
        <section className="text-left">
          <p><i className="pi pi-calendar" aria-hidden="true"></i> <strong>Fecha: </strong>{invoice?.date}</p>
          <p><i className="pi pi-dollar" aria-hidden="true"></i> <strong>Monto ($): </strong>{currencyFormat(invoice?.amount_dolar)}</p>
          <p><i className="pi pi-dollar" aria-hidden="true"></i> <strong>Monto (Bs.): </strong>{currencyFormat(invoice?.amount_bs)}</p>
          <p><i className="pi pi-arrow-right-arrow-left" aria-hidden="true"></i> <strong>Tasa: </strong>{currencyFormat(invoice?.rate)} Bs.</p>
        </section>
        <section className="text-left">
          <p><i className="pi pi-hashtag" aria-hidden="true"></i> <strong>Nro. de referencia: </strong>{invoice?.reference_payment}</p>
          <p><i className="pi pi-building-columns" aria-hidden="true"></i> <strong>Banco emisor: </strong>{getBankNameFromId(invoice?.bank_emitter, banks)}</p>
          <p><i className="pi pi-building-columns" aria-hidden="true"></i> <strong>Banco beneficiario: </strong>{getBankNameFromId(invoice?.bank_receiver, banks)}</p>
        </section>
      </div>
      <br></br>
      <div className="flex justify-content-center gap-3">
        <Button
          type="submit"
          label="Eliminar"
          severity="danger"
          onClick={() => deleteAdvanceMutation.mutate(invoice.id)}
          disabled={deleteAdvanceMutation.isPending}
          loading={deleteAdvanceMutation.isPending}
        />
        <Button
          type="button"
          label="Cancelar"
          style={{ backgroundColor: "#999999", borderColor: "#999999" }}
          onClick={onHide}
          disabled={deleteAdvanceMutation.isPending}
        />
      </div>
    </Dialog>
  )
}
