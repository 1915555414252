import React from 'react';
import Dashboard from "../Layout/Dashboard";
import CreateCase from './CreateCase';

function MyComponent() {
    return (
        <Dashboard headerTitle="Nuevo caso" panelClassName="w-12 md:w-9 lg:w-7">
            <CreateCase></CreateCase>
        </Dashboard>        
    );
}

export default MyComponent;