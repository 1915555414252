import { Dropdown } from "primereact/dropdown";
import Dashboard from "../Layout/Dashboard";
import "./Advances.css";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAdvance } from "../services/advances";
import { currencyFormat, formatDate, getBankNameFromId } from "../utils";
import { Toast } from "primereact/toast";
import VSieteCurrencyInput from "../components/VSieteCurrencyInput";
import { Dialog } from "primereact/dialog";
import { useBanks, useMedicalCenters } from "../hooks/queries";

const today = new Date();

/**
 * New advance form
 */
export default function CreateAdvance() {
  // Form state
  const [formState, setFormState] = useState({
    medicalCenter: null,
    advanceDate: today,
    amount: 0,
    rate: 0,
    emittingBank: null,
    receivingBank: null,
    refNumber: "",
    amountBs: null,
    amountBsFloat: 0,
    description: "",
  });

  // Confirmation dialog
  const [showConfirmation, setShowConfirmation] = useState(false);

  // Form message toast
  const toast = useRef(null);

  // Get medical centers for dropdown
  const medicalCentersQuery = useMedicalCenters();

  // Get banks for dropdown
  const banksQuery = useBanks();

  const queryClient = useQueryClient();

  // Add advance mutation
  const addAdvanceMutation = useMutation({
    mutationFn: () => {
      return createAdvance({
        medical_center: formState.medicalCenter,
        amount_dolar: formState.amount,
        rate: formState.rate,
        amount_bs: formState.amountBsFloat || 0,
        // amount_bs: (formState.amount * formState.rate).toFixed(2),
        date: formatDate(formState.advanceDate),
        reference_payment: formState.refNumber,
        bank_emitter: formState.emittingBank,
        bank_receiver: formState.receivingBank,
        description: formState.description,
      });
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: ["advances", formState.medicalCenter],
      });
      if (response.type === "error") {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.message,
        });
        setShowConfirmation(false);
      } else {
        toast.current.show({
          severity: "success",
          summary: "Éxito",
          detail: response.message,
        });
        setFormState({
          medicalCenter: null,
          advanceDate: today,
          amount: 0,
          rate: 0,
          emittingBank: null,
          receivingBank: null,
          refNumber: "",
        });
        setShowConfirmation(false);
      }
    },
  });

  // Update amount
  // const amountBs = ((formState.amount || 0) * (formState.rate || 0)).toFixed(2);
  const amountBs = formState.amountBs;
  const amountBsFloat = formState.amountBsFloat;

  // Disable form submit if inputs aren't filled
  const canSubmit =
    !addAdvanceMutation.isPending &&
    Object.keys(formState).every((key) => {
      if (amountBsFloat < 0 && key === "refNumber") return true;
      if (amountBsFloat > 0 && key === "description") return true;
      return formState[key] !== null && formState[key] !== "";
    });


  return (
    <Dashboard headerTitle="Nuevo Anticipo">
      <Toast ref={toast} position="top-center" />
      <form id="new-advance-form" className="p-fluid">
        <div className="p-field">
          <label htmlFor="medicalCenter">Proveedor</label>
          <Dropdown
            inputId="medicalCenter"
            name="medicalCenter"
            loading={medicalCentersQuery.isLoading}
            disabled={medicalCentersQuery.isLoading}
            value={formState.medicalCenter}
            onChange={(e) =>
              setFormState({ ...formState, medicalCenter: e.value })
            }
            options={medicalCentersQuery.data ?? []}
            optionValue="id"
            optionLabel="name"
            placeholder="Seleccione un proveedor"
            filter
            required
            emptyMessage="No se encontraron proveedores"
          />
        </div>
        <div className="p-field">
          <label htmlFor="date">Fecha del anticipo</label>
          <Calendar
            inputId="date"
            name="date"
            showIcon
            dateFormat="dd/mm/yy"
            value={formState.advanceDate}
            onChange={(e) =>
              setFormState({ ...formState, advanceDate: e.value })
            }
            maxDate={today}
            required
          />
        </div>
        {/* <div className="p-field">
          <label htmlFor="amount">Monto ($)</label>
          <VSieteCurrencyInput
            id="amount"
            name="amount"
            prefix="$"
            onValueChange={(_value, _name, values) => setFormState({ ...formState, amount: values.float })}
          />
        </div> */}
        <div className="p-field">
          {/* <div>
            <label htmlFor="rate">Tasa de cambio (Bs.)</label>
            <VSieteCurrencyInput
              id="rate"
              name="rate"
              prefix="Bs."
              onValueChange={(_value, _name, values) => setFormState({ ...formState, rate: values.float })}
            />
          </div> */}
          <div>
            <label htmlFor="amountBs">Monto (Bs.)</label>
            <VSieteCurrencyInput
              id="amountBs"
              name="amountBs"
              prefix="Bs."
              allowNegativeValue={true}
              value={amountBs}
              onValueChange={(_value, _name, values) =>
                setFormState({
                  ...formState,
                  amountBs: values.value,
                  amountBsFloat: values.float,
                })
              }
            />
          </div>
        </div>
        <div className="p-field">
          <label htmlFor="emittingBank">Banco emisor</label>
          <Dropdown
            inputId="emittingBank"
            name="emittingBank"
            loading={banksQuery.isLoading}
            disabled={banksQuery.isLoading}
            options={banksQuery.data ?? []}
            optionValue="id"
            optionLabel="name"
            placeholder="Seleccione un banco"
            value={formState.emittingBank}
            onChange={(e) =>
              setFormState({ ...formState, emittingBank: e.value })
            }
            filter
            required
            emptyMessage="No se encontraron bancos"
          />
        </div>
        <div className="p-field">
          <label htmlFor="receivingBank">Banco beneficiario</label>
          <Dropdown
            inputId="receivingBank"
            name="receivingBank"
            loading={banksQuery.isLoading}
            disabled={banksQuery.isLoading}
            options={banksQuery.data ?? []}
            optionValue="id"
            optionLabel="name"
            placeholder="Seleccione un banco"
            value={formState.receivingBank}
            onChange={(e) =>
              setFormState({ ...formState, receivingBank: e.value })
            }
            filter
            required
            emptyMessage="No se encontraron bancos"
          />
        </div>

        {amountBsFloat > 0 && (
          <div className="p-field">
            <label htmlFor="refNumber">Nro. de referencia</label>
            <InputText
              id="refNumber"
              name="refNumber"
              type="text"
              required
              value={formState.refNumber}
              onChange={(e) =>
                setFormState({ ...formState, refNumber: e.target.value })
              }
              keyfilter="pint"
            />
          </div>
        )}
        {amountBsFloat < 0 && (
          <div className="p-field">
            <label htmlFor="description">Descipción</label>
            <InputText
              id="description"
              name="description"
              type="text"
              required={amountBsFloat < 0}
              value={formState.description}
              onChange={(e) =>
                setFormState({ ...formState, description: e.target.value })
              }
              // keyfilter="pint"
            />
          </div>
        )}

        {/* Confirmation dialog */}
        <Dialog
          header="Confirmar anticipo"
          visible={showConfirmation}
          onHide={() => {
            if (!showConfirmation) return;
            setShowConfirmation(false);
          }}
          style={{ maxWidth: "40vw" }}
          className="advance-modal"
          contentClassName="advance-modal-content"
          draggable={false}
        >
          <div className="p-fluid">
            <p className="text-center text-xl">
              <strong>
                ¿Seguro que desea registrar el anticipo con los siguientes
                datos?
              </strong>
            </p>
            <section>
              <p>
                <i className="pi pi-calendar" aria-hidden="true"></i>{" "}
                <strong>Fecha: </strong>
                {formState.advanceDate.toLocaleDateString()}
              </p>
              {/* <p><i className="pi pi-dollar" aria-hidden="true"></i> <strong>Monto ($): </strong>{currencyFormat(formState.amount)}</p> */}
              <p>
                <i className="pi pi-dollar" aria-hidden="true"></i>{" "}
                <strong>Monto (Bs.): </strong>
                {currencyFormat(amountBsFloat)}
              </p>
              {/* <p><i className="pi pi-arrow-right-arrow-left" aria-hidden="true"></i> <strong>Tasa: </strong>{currencyFormat(formState.rate)} Bs.</p> */}
            </section>
            <section>
              <p>
                <i className="pi pi-hashtag" aria-hidden="true"></i>{" "}
                <strong>Nro. de referencia: </strong>
                {formState.refNumber}
              </p>
              <p>
                <i className="pi pi-building-columns" aria-hidden="true"></i>{" "}
                <strong>Banco emisor: </strong>
                {getBankNameFromId(formState.emittingBank, banksQuery.data)}
              </p>
              <p>
                <i className="pi pi-building-columns" aria-hidden="true"></i>{" "}
                <strong>Banco beneficiario: </strong>
                {getBankNameFromId(formState.receivingBank, banksQuery.data)}
              </p>
            </section>
          </div>
          <br></br>
          <div className="delete-confirm-buttons-container">
            <Button
              type="button"
              label="Crear"
              onClick={() => addAdvanceMutation.mutate()}
            />
            <Button
              type="button"
              label="Cancelar"
              style={{ backgroundColor: "#999999", borderColor: "#999999" }}
              onClick={() => setShowConfirmation(false)}
            />
          </div>
        </Dialog>

        <Button
          label="Guardar"
          type="button"
          disabled={!canSubmit}
          onClick={() => setShowConfirmation(true)}
        />
      </form>
    </Dashboard>
  );
}
