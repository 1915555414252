import { InputText } from "primereact/inputtext";

/**
 * InputMask adaptation for native-like date inputs
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.value
 * @param {(val: string) => void} props.setValue
 * @param {boolean} props.disabled
 */
export default function VSieteDateInput({ name, value, setValue, disabled }) {
  
  /**
   * @param {React.KeyboardEvent<HTMLInputElement>} e 
   */
  const handleKeyDown = (e) => {
    if (e.ctrlKey || e.shiftKey) {
      // Allow default copy/paste behavior
      return;
    }

    let input = e.target.value.replace(/\D/g, "");
    let formatted = "";

    if (e.key >= '0' && e.key <= '9') {
      if (input.length === 0) {
        if (e.key > '3') {
          input += '0' + e.key;
        } else {
          input += e.key;
        }
      } else if (input.length === 2 && e.key > '1') {
        input += '0' + e.key;
      } else {
        input += e.key;
      }
    } else if (e.key === 'Backspace') {
      input = input.slice(0, -1);
    } else {
      e.preventDefault();
      return;
    }

    if (input.length >= 2) {
      formatted += input.slice(0, 2) + "/";
      input = input.slice(2);
    }
    if (input.length >= 2) {
      const month = input.slice(0, 2);
      if (parseInt(month, 10) > 12) {
        input = input.slice(1); // Remove the invalid month digit
      } else {
        formatted += month + "/";
        input = input.slice(2);
      }
    }
    if (input.length > 0) {
      formatted += input.slice(0, 4);
    }

    setValue(formatted);
    e.preventDefault();
  };

  /**
   * @param {React.ClipboardEvent<HTMLInputElement>} e 
   */
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text").replace(/\D/g, "");
    if (pastedText.length === 8) {
      const day = pastedText.slice(0, 2);
      const month = pastedText.slice(2, 4);
      const year = pastedText.slice(4, 8);
      if (parseInt(day, 10) <= 31 && parseInt(month, 10) <= 12 && (year[0] === '1' || year[0] === '2')) {
        const formatted = `${day}/${month}/${year}`;
        setValue(formatted);
      }
    }
  };

  return (
    <InputText
      name={name}
      placeholder="dd/mm/aaaa"
      value={value}
      onPaste={handlePaste}
      onKeyDown={handleKeyDown}
      disabled={disabled}
    />
  );
}
