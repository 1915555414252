import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { UserRole } from "../constants";

/**
 * Modal para informar a la clínica que debe agendar el caso
 * @param {object} props
 * @param {boolean} props.visible
 * @param {() => void} props.onHide
 * @param {string} props.patientName
 * @param {string} props.phoneNumber
 * @param {number} props.daysLeft
 * @param {() => void} props.onAccept
 * @param {boolean} props.loading
 */
export default function HospitalPendingModal({
  visible,
  onHide,
  patientName,
  phoneNumber,
  daysLeft,
  onAccept,
  loading,
}) {
  const role = localStorage.getItem("role");
  if (role !== UserRole.MEDICAL_CENTER) {
    return <></>;
  }

  return (
    <Dialog
      header="Caso Pendiente"
      visible={visible}
      onHide={onHide}
      draggable={false}
    >
      <p>Este caso clínico está pendiente, tienes {daysLeft} días para agendarlo</p>
      <p><b>Nombre del paciente:</b> {patientName}</p>
      <p><b>Número de Teléfono:</b> {phoneNumber}</p>
      <div className="delete-confirm-buttons-container">
        <Button
          label="Cancelar"
          severity="secondary"
          onClick={onHide}
          loading={loading}
          disabled={loading}
        />
        <Button
          label="Agendar"
          onClick={onAccept}
          loading={loading}
          disabled={loading}
        />
      </div>
    </Dialog>
  );
}
